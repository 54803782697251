

.App-link {
  color: #61dafb;
}


.profile {
  margin-bottom: 10px;
}

.profile-image {
  margin: 5px;
  margin-right: 15px;
}

.profile-data {
  margin: 5px;
}

.create-playlist-form {
  margin: 15px auto 15px auto;
}

.create-playlist-directions h3 {
  margin: 3px auto;
}

.create-playlist-directions p {
  margin: 3px auto;
}

.create-playlist-directions {
  border: 1px solid black;
  padding: 10px;
  border-radius: 10px;
}